export default {
	get(key) {
		let parts = ('; ' + document.cookie).split('; ' + key + '=');
		if (parts.length >= 2) return parts.pop().split(';').shift();
	},
	set(key, val, sec, domain) {
		let expires = '', domainExpr = '';

		if (sec) {
			let date = new Date();
			date.setTime(date.getTime() + (sec * 1000));
			expires = '; expires=' + date.toUTCString();
		}

		if (domain) {
			domainExpr = '; domain=' + domain;
		}

		let expr = key + '=' + (val || '') + expires + domainExpr + '; path=/; SameSite=Lax';
		document.cookie = expr;

		return expr;
	},
	remove(key) {
		this.set(key, '', -86400 * 7);
	}
}