import util from './lib/util';
import { PageObserver } from './lib/dom-json';
import cookie from './lib/cookie';
import json from './lib/json';
import dom from './lib/dom';
import libUrl from './lib/url';

const COUNTER_VERSION = 1.0;

const SESSION_COOKIE_NAME = 'si_ses_id';
const VISITOR_COOKIE_NAME = 'si_usr_id';
const AB_COOKIE_PREFIX = 'si_ab_';
const EVENTS_INTERVAL = 2000;
const SESSION_LIFETIME = 15;

let endpoint = 'https://statinside.com';

let cookieDomain;

let viewId;
let sessionId = cookie.get(SESSION_COOKIE_NAME);
let visitorId = cookie.get(VISITOR_COOKIE_NAME);
let accountId;

let pageName = '';

let events = [];
let features = [];
let sentEvents = 0;

let activeTime = 0;
let maxScrollPercent = 0;
let pageState = document.hasFocus ? (document.hasFocus() ? 'active' : 'inactive') : 'active';

let clicksCnt = 0;
let mDownCnt = 0;
let mUpCnt = 0;

//let frame, pageBuilder;

let customData = {};

let jsonpCallbackNum = 0;

let startTime = new Date().getTime();

let shouldRecord = false;
let pageObserver;

let actionsToSend = [];

function start() {
	initScriptParams();
	initCustomData();

	initActiveTime();
	initUserEvents();
	initObserver();

	initCommands();

	addHit(null, res => {
		let data;
		if (typeof res === 'string') {
			data = json.decode(res, {});
		} else {
			data = res;
		}

		if (data.res && data.res.trackErrors) {
			initErrors();
		}

		shouldRecord = data.res && data.res.record;
		if (shouldRecord) {
			pageObserver.startRecording();
		}

		viewId = data.res && data.res.viewId || generateId('v');
		sendActions();
		initUser();
		initSession();

		sendEvents(true);
	});
}

function initCommands() {
	if (!window.addEventListener) return;

	let capture;

	window.addEventListener('message', e => {
		if (e.data.command === 'capture') {
			if (capture) return;

			setTimeout(() => {
				capture = pageObserver.capture();
				e.source.window.postMessage({ command: 'capture_response', capture }, '*');
			}, 1000);
		}
	});
}

function initErrors() {
	if (!window.addEventListener) return;

	window.addEventListener('error', err => {
		try {
			let stack = err.error && err.error.stack;

			let data = {
				ts: Math.floor(err.timeStamp),
				msg: err.message,
				file: err.filename,
				line: err.lineno,
				col: err.colno,
				stack
			};

			events.push({
				event: 'error',
				time: new Date().getTime(),
				data
			});
		} catch (err) {

		}
	});
}

function initScriptParams() {
	let scripts = document.getElementsByTagName('script');
	for (let i = 0; i < scripts.length; i++) {
		let accId = scripts[i].getAttribute('data-statinside-id');
		if (accId) {
			accountId = accId;
			endpoint = scripts[i].getAttribute('data-endpoint') || endpoint;
			break;
		}
	}
}

function initCustomData() {
	let arr = window._siData;
	if (arr && arr instanceof Array) {
		for (let i = 0; i < window._siData.length; i++) {
			let key = arr[i][0];
			let val = arr[i][1];
			let extra = arr[i][2];

			customData[key] = val;

			handleCustomData(key, val, extra);
		}
	}
	window.statInside = (key, val, extra) => {
		customData[key] = val;
		handleCustomData(key, val, extra, true);
	}
}

function handleCustomData(key, val, extra, loaded = false) {
	if (key === 'tag' || key === 'tags' || key === 'goal') {
		if (val instanceof Array) {
			for (let i = 0; i < val.length; i++) {
				features.push({
					type: 'tag',
					key: val[i]
				});
			}
		} else {
			features.push({type: 'tag', key: val});
		}
		loaded && sendEvents();
	} else if (key === 'userId') {
		features.push({type: 'userId', key: val});

		loaded && sendEvents();
	} else if (key === 'attr') {
		if (val && typeof val === 'object') {
			for (let i in val) {
				if (val.hasOwnProperty && !val.hasOwnProperty(i)) continue;

				features.push({
					type: key,
					key: i,
					val: val[i]
				})
			}
		} else {
			features.push({
				type: key,
				key: val,
				val: extra
			});
		}

		loaded && sendEvents();
	} else if (key === 'ab') {
		setTimeout(function() {
			initAB(val, extra, loaded);
		}, 1);
	} else if (key === 'action') {
		if (viewId) {
			sendAction(val, extra, true);
		} else {
			actionsToSend.push([val, extra]);
		}
	} else if (key === 'hit') {
		addHit({repeat: true}, res => {
			let data;
			if (typeof res === 'string') {
				data = json.decode(res, {});
			} else {
				data = res;
			}
			viewId = data.res && data.res.viewId || viewId;
		});
	} else if (key === 'page') {
		pageName = val;
	} else if (key === 'cookie') {
		cookieDomain = val;
	}
}

function initAB(opts, callback, loaded) {
	if (!opts.name || typeof opts.name !== 'string') {
		throw new Error('A/B testing opts.name must be string');
	}

	let cookieName = AB_COOKIE_PREFIX + opts.name;
	let variant = cookie.get(cookieName);

	if (!variant) {
		let sum = 0;
		let list = [];
		for (let val in opts.variants) {
			if (opts.variants.hasOwnProperty && !opts.variants.hasOwnProperty(val)) continue;

			let weight = parseInt(opts.variants[val]) || 1;
			sum += weight;
			list.push({
				val: val,
				top: sum
			});
		}

		let r = Math.floor(Math.random() * sum);
		for (let i = 0; i < list.length; i++) {
			if (r < list[i].top) {
				variant = list[i].val;
				break;
			}
		}
	}

	if (!variant) return;

	let ttl = SESSION_LIFETIME * 60;
	setCookie(cookieName, variant, ttl);
	setInterval(() => {
		setCookie(cookieName, variant, ttl);
	}, 60 * 1000);

	features.push({
		type: 'ab',
		key: opts.name,
		val: variant
	});
	if (loaded) {
		sendEvents();
	}

	callback(variant);
}

function sendActions() {
	for (let i = 0; i < actionsToSend.length; i++) {
		let act = actionsToSend[i];
		let [action, data] = act;
		sendAction(action, data, false);
	}

	actionsToSend = [];
}

function sendAction(action, custom, isInstant) {
	addHit({ action, custom });
}

function initUser() {
	if (!visitorId) {
		visitorId = viewId;
	}
	let year = 3600 * 24 * 365;
	setCookie(VISITOR_COOKIE_NAME, visitorId, year);
}

function initSession() {
	if (!sessionId) {
		sessionId = viewId;
	}

	let key = SESSION_COOKIE_NAME;
	let ttl = SESSION_LIFETIME * 60;

	setCookie(key, sessionId, ttl);

	setInterval(() => {
		setCookie(key, sessionId, ttl);
	}, 60 * 1000);
}

function setCookie(key, val, sec) {
	cookie.set(key, val, sec, cookieDomain);
}

function initActiveTime() {
	const interval = 1000;

	setTimeout(function iter() {
		if (document.hasFocus && document.hasFocus()) {
			activeTime += interval;
		}

		setTimeout(iter, interval);
	}, interval);
}

function initUserEvents() {
	dom.on(document, 'click', e => clicksCnt++);
	dom.on(document, 'mousedown', e => mDownCnt++);
	dom.on(document, 'mouseup', e => mUpCnt++);

	dom.on(window, 'scroll', e => {
		if (maxScrollPercent === 100) return;

		try {
			let winHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
			let docHeight = document.documentElement.scrollHeight;
			let scrollTop = window.pageYOffset;

			let percent = Math.round(scrollTop / (docHeight - winHeight) * 100);
			if (percent > 100) return;

			let scroll = Math.max(percent, maxScrollPercent);
			if (scroll > maxScrollPercent) {
				maxScrollPercent = scroll;
			}
		} catch (err) {

		}
	});
}

function initObserver() {
	pageObserver = new PageObserver(window, { callback });
	pageObserver.init();

	function callback(e) {
		if (e.event === 'state') {
			pageState = e.data;
			if (!shouldRecord) return;
		}

		events.push({
			event: e.event,
			time: new Date().getTime(),
			data: e.data
		});

		/*if (!pageBuilder || !frame) return;

		pageBuilder.applyEvent(e.event, e.data);
		if (e.event === 'resize') {
			frame.style.width = e.data.width + 'px';
			frame.style.height = e.data.height + 'px';
		}*/
	}
}

function addHit(opts, callback) {
	let url = endpoint + '/api/add-hit';

	let data = {
		ver: COUNTER_VERSION,
		viewId: opts && opts.repeat ? undefined : viewId,
		visitorId,
		sessionId,
		userId: customData.userId,
		custom: opts ? opts.custom : customData.custom,
		group: customData.group,
		accountId,
		referrer: document.referrer,
		url: location.href,
		page: pageName,
		topFrame: (window.top === window ? 1 : 0),
		//title: document.title.substring(0, 80),
		tz: new Date().getTimezoneOffset(),
		features,
		action: opts ? opts.action : undefined
	};

	features = [];

	populateScreenData(data);
	populateBotData(data);

	post(url, data, callback);
}

function populateScreenData(data) {
	if (window.screen) {
		data.screen = screen.width + '*' + screen.height + '*' + (screen.colorDepth ? screen.colorDepth : screen.pixelDepth) + '*' + (window.devicePixelRatio || 0);
	}
}

function populateBotData(data) {
	try {
		data.notifPerm = window.Notification && Notification.permission || '';

		data.navLang = navigator.language;
		data.navLangs = navigator.languages instanceof Array ? navigator.languages.join(',') : '';

		data.webdriver = navigator.webdriver;

		data.rtt = navigator.connection && navigator.connection.rtt || 0;

		data.mimeCnt = navigator.mimeTypes ? navigator.mimeTypes.length : null;
		data.pluginsCnt = navigator.plugins ? navigator.plugins.length : null;
	} catch (err) {

	}
}

function sendEvents(loop = false) {
	if (loop) {
		setTimeout(() => sendEvents(true), EVENTS_INTERVAL);
	}

	let featuresOnly = (customData.heartbeat === false);
	if (featuresOnly && !features.length) return;

	let domain = location.hostname;
	let time = Math.round(new Date().getTime() - startTime);

	let data = {
		num: ++sentEvents,
		viewId,
		sessionId,
		visitorId,
		accountId,
		domain,
		time,
		activeTime,
		scroll: maxScrollPercent,
		clicksCnt,
		mDownCnt,
		mUpCnt,
		features
	};

	if (!featuresOnly) {
		data.events = events;
		events = [];
	}

	features = [];

	let url = endpoint + '/api/send-heartbeat';
	sendData(url, data);
}

function generateId(type) {
	let ts = new Date().getTime();
	return Math.round(ts / 1000).toString() + '-' + (type || '') + '-' + util.getRandomString(20);
}

function sendData(url, data) {
	//TODO: replace navigator.sendBeacon with fetch keepalive
	if (navigator.sendBeacon && navigator.sendBeacon(url, json.encode(data))) return;

	post(url, data);
}

function post(url, data, callback) {
	if (!window.XMLHttpRequest) {
		jsonp(url, data, callback);
	} else {
		ajax(url, data, callback);
	}
}

function jsonp(url, data, callback) {
	let script = document.createElement('script');
	document.body.appendChild(script);

	jsonpCallbackNum++;
	let funcName = 'statinsideCallback' + jsonpCallbackNum;
	window[funcName] = res => callback && callback(res);

	let unique = new Date().getTime() + Math.random();
	let qs = libUrl.queryToString(data) + '&callback=window.' + funcName + '&_r=' + unique;
	script.src = url + '?' + qs;
}

function ajax(url, data, callback) {
	let dataString = json.encode(data);

	let xhr = new XMLHttpRequest();

	xhr.open('POST', url);
	xhr.setRequestHeader('Content-Type', 'text/plain');
	xhr.onreadystatechange = () => {
		if (callback && xhr.readyState === 4) {
			callback(xhr.responseText);
		}
	};
	xhr.send(dataString);
}

/*function debug() {
	frame = createFrame();
	pageBuilder = new PageBuilder(frame.contentWindow);

	events.forEach(e => pageBuilder.applyEvent(e.event, e.data));

	start();
}

function createFrame() {
	let frame = document.createElement('iframe');
	frame.src = 'about:blank';
	frame.__siIgnore = true;

	let styles = {
		position: 'fixed',
		right: '10px',
		top: '10px',
		transform: 'scale(0.4)',
		transformOrigin: '100% 0',
		zIndex: 999999,
		width: window.innerWidth + 'px',
		height: window.innerHeight + 'px'
	};
	for (let i in styles) {
		frame.style[i] = styles[i];
	}

	document.body.insertBefore(frame, document.body.firstChild);

	return frame;
}*/

start();

//window.StatInside = { debug };