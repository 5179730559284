import du from './dom';

const HOVER_CLASS = '__ci_hover__';

function log(data) {
	console.warn(data);
}

du.setLogger(log);

export function PageObserver(win, opts) {
	this.opts = opts;

	this.win = win;
	this.doc = this.win.document;

	this.nextNodeId = 0;
}

PageObserver.prototype.init = function() {
	du.on(this.win, 'click', e => {
		let x = e.clientX;
		let y = e.clientY;
		let width = window.innerWidth;
		let height = window.innerHeight;
		this.emit('click', { x, y, width, height });
	});

	du.on(this.win, 'focus', e => {
		this.emit('state', 'active');
	});
	
	du.on(this.win, 'blur', e => {
		this.emit('state', 'inactive');
	});
};

PageObserver.prototype.capture = function() {
	let docType = this.getPageDocType();
	let tree = this.serializeNode(this.doc.documentElement);

	return { docType, tree };
};

PageObserver.prototype.startRecording = function() {
	if (!window.Node || !window.XMLHttpRequest) return;
	
	let docType = this.getPageDocType();
	let tree = this.serializeNode(this.doc.documentElement);

	let url = this.win.location.href;
	let width = window.innerWidth;
	let height = window.innerHeight;
	let scrollX = this.win.scrollX;
	let scrollY = this.win.scrollY;
	this.emit('navigate', { url, width, height, scrollX, scrollY, docType, tree });

	this.observeMutations();
	
	du.on(this.doc, 'mouseover', e => {
		this.emit('hover', e.target.__siId);
	});

	du.on(this.win, 'resize', () => {
		let width = window.innerWidth;
		let height = window.innerHeight;
		this.emit('resize', { width, height });
	});
	
	du.on(this.win, 'scroll', () => {
		let x = this.win.scrollX;
		let y = this.win.scrollY;
		this.emit('scroll', { x, y });
	});
	
	du.on(this.win, 'mousemove', e => {
		let x = e.clientX;
		let y = e.clientY;
		this.emit('mousemove', { x, y });
	});
};

PageObserver.prototype.emit = function(event, data) {
	this.opts.callback({ event, data });
};

PageObserver.prototype.getPageDocType = function() {
	let dt = this.doc.doctype;
	if (!dt) return '';

	let str = '<!DOCTYPE ' + (dt.name || 'html');
	if (dt.publicId) {
		str += ' PUBLIC "' + dt.publicId + '"';
	}
	if (!dt.publicId && dt.systemId) {
		str += " SYSTEM";
	}
	if (dt.systemId) {
		str += ' "' + dt.systemId + '"';
	}
	str += '>';
	return str;
};

PageObserver.prototype.serializeNode = function(node) {
	if (!node.__siId) {
		node.__siId = ++this.nextNodeId;
	}
	let dom = {
		nodeType: node.nodeType,
		id: node.__siId
	};
	switch (dom.nodeType) {
		case Node.DOCUMENT_TYPE_NODE:
			dom.name = (node.name !== '' ? node.name :  'html');
			dom.publicId = node.publicId;
			dom.systemId = node.systemId;
			break;
		case Node.COMMENT_NODE:
		case Node.TEXT_NODE:
			dom.textContent = node.textContent || '';
			break;
		case Node.ELEMENT_NODE:
			dom.tagName = node.tagName;
			dom.attributes = {};

			for (let i = 0; i < node.attributes.length; i++) {
				let attr = node.attributes[i];
				dom.attributes[attr.name] = attr.nodeValue;
			}
			if (node && node.namespaceURI && node.namespaceURI !== 'http://www.w3.org/1999/xhtml') {
				dom.namespaceURI = node.namespaceURI;
			}
			if (node.tagName === 'LINK' && node.getAttribute('rel') === 'stylesheet' && node.href) {
				dom.attributes.href = node.href;
				dom.dataType = 'css';
			}
			if (dom.tagName === 'IMG' && node.currentSrc) {
				dom.attributes.src = node.currentSrc;
			}
			if (dom.tagName === 'SCRIPT' || dom.tagName === 'NOSCRIPT') {
				dom.childNodes = [{
					nodeType: Node.TEXT_NODE,
					textContent: ''
				}];
			} else if (node.childNodes.length) {
				dom.childNodes = [];
				for (let child = node.firstChild; child; child = child.nextSibling) {
					if (!child.__siIgnore) {
						dom.childNodes.push(this.serializeNode(child))
					}
				}
			}
	}
	return dom;
};

PageObserver.prototype.observeMutations = function() {
	if (!window.MutationObserver) return;

	let targetNode = document.documentElement;
	let config = { attributes: true, childList: true, subtree: true };
	let onMutation = (mutationsList, observer) => {
		for (let i = 0; i < mutationsList.length; i++) {
			let mutation = mutationsList[i];
			if (mutation.type === 'attributes') {
				this.emit('mutation', {
					id: mutation.target.__siId,
					type: mutation.type,
					attr: mutation.attributeName,
					val: mutation.target.getAttribute(mutation.attributeName)
				});
			} else if (mutation.type === 'childList') {
				let removed = [];
				mutation.removedNodes.forEach(node => {
					let id = node.__siId;
					if (id) {
						removed.push(id);
					}
				});

				let added = [];
				mutation.addedNodes.forEach(node => {
					if (node.__siIgnore) return;

					let tree = this.serializeNode(node);
					if (tree) {
						let nextId = mutation.nextSibling ? mutation.nextSibling.__siId : null;
						added.push([tree, nextId]);
					}
				});

				this.emit('mutation', {
					id: mutation.target.__siId,
					type: mutation.type,
					removed,
					added
				});
			}
		}
	};

	new MutationObserver(onMutation).observe(targetNode, config);
};

export function PageBuilder(win) {
	this.win = win;
	this.doc = this.win.document;

	this.idNodeMap = {};
}

PageBuilder.prototype.applyEvent = function(event, data) {
	switch (event) {
		case 'navigate':
			this.deserializeDoc(data);
			break;
		case 'mutation':
			this.performMutation(data);
			break;
		case 'hover':
			this.forceMouseOver(data);
			break;
		case 'scroll':
			this.forceScroll(data);
			break;
	}
};

PageBuilder.prototype.deserializeDoc = function(dom) {
	let fragment = this.deserializeNode(dom.tree);

	let head = fragment.querySelector('head');

	let base = this.doc.createElement('base');
	base.setAttribute('href', location.href);

	let nodes = head.childNodes;
	if (nodes.length === 0) {
		head.appendChild(base);
	} else {
		head.insertBefore(base, nodes[0]);
	}

	let csp = this.doc.createElement('meta');
	csp.setAttribute('http-equiv', 'Content-Security-Policy');
	csp.setAttribute('content', 'upgrade-insecure-requests');
	this.doc.head.appendChild(csp);

	this.doc.write(dom.docType);
	this.doc.childNodes.forEach(child => this.doc.removeChild(child));
	this.doc.appendChild(fragment);

	this.addHoverStyle();

	this.doc.querySelectorAll('*').forEach(el => {
		for (let i = 0; i < el.attributes.length; i++) {
			let attr = el.attributes[i];
			el.setAttribute(attr.name, attr.nodeValue);
		}
	});
};

PageBuilder.prototype.performMutation = function(data) {
	if (data.type === 'attributes') {
		let node = this.idNodeMap[data.id];
		if (!node || !node.setAttribute) return;
		node.setAttribute(data.attr, data.val);
	} else if (data.type === 'childList') {
		data.removed.forEach(id => {
			let node = this.idNodeMap[id];
			try {
				du.remove(node.parentNode, node);
			} catch (err) {
				log(err);
			}
		});

		data.added.forEach(arr => {
			let obj = arr[0];
			let nextId = arr[1];
			let node = this.deserializeNode(obj);

			let parentNode = this.idNodeMap[data.id];

			if (!nextId) {
				du.append(parentNode, node);
			} else {
				let nextNode = this.idNodeMap[nextId];
				du.before(node, nextNode);
			}
		})
	}
};

PageBuilder.prototype.deserializeNode = function(dom, parent = null) {
	let error = null;

	if (!dom) return null;
	let node = this.idNodeMap[dom.id];

	switch (dom.nodeType) {
		case Node.COMMENT_NODE:
			node = this.doc.createComment(dom.textContent);
			break;
		case Node.TEXT_NODE:
			node = this.doc.createTextNode(dom.textContent);
			break;
		case Node.DOCUMENT_TYPE_NODE:
			node = this.doc.implementation.createDocumentType(dom.name, dom.publicId, dom.systemId);
			break;
		case Node.ELEMENT_NODE:
			let tagName = dom.tagName;
			let ignoredRags = ['SCRIPT'];
			let ignored = false;
			if (ignoredRags.indexOf(tagName) !== -1) {
				tagName = 'DISABLED-' + tagName;
				ignored = true;
			}
			try {
				node = this.doc.createElement(tagName);
			} catch (err) {
				console.warn(err);
				error = true;
			}
			if (ignored) {
				node.style.display = 'none';
			}
			for (let attrKey in dom.attributes) {
				if (dom.attributes.hasOwnProperty(attrKey)) {
					let val = dom.attributes[attrKey];
					du.attr(node, attrKey, val);
				}
			}
	}
	if (!node) {
		console.warn('Unknown node type: ' + dom.nodeType);
		return;
	}

	this.idNodeMap[dom.id] = node;
	if (parent) {
		parent.appendChild(node);
	}
	if (dom.childNodes && !error) {
		for (let i = 0; i < dom.childNodes.length; i++) {
			this.deserializeNode(dom.childNodes[i], node);
		}
	}

	return node;
};

PageBuilder.prototype.forceMouseOver = function(nodeId) {
	let node = this.idNodeMap[nodeId];

	this.doc.querySelectorAll('.' + HOVER_CLASS).forEach(el => {
		el.classList.remove(HOVER_CLASS);
	});

	while (node && node.classList) {
		node.classList.add(HOVER_CLASS);
		node = node.parentNode;
	}
};

PageBuilder.prototype.forceScroll = function(data) {
	this.win.scrollTo(data.x, data.y);
};

PageBuilder.prototype.addHoverStyle = function() {
	for (let i = 0; i < this.doc.styleSheets.length; i++) {
		let rules;
		try {
			rules = this.doc.styleSheets[i].cssRules;
		} catch (err) {
			continue;
		}
		for (let j = 0; j < rules.length; j++) {
			let rule = rules[j];
			if (rule.selectorText) {
				rule.selectorText = rule.selectorText.replace(/:hover/g, '.' + HOVER_CLASS);
			}
		}
	}
};

export default {
	PageObserver, PageBuilder
};