function stringifyFallback(data) {
	if (data === undefined) return undefined;
	if (data === null) return 'null';
	if (data.toString() === "NaN") return 'null';
	if (data === Infinity) return 'null';
	if (typeof data === 'string') return '"' + data.replace(/"/g, '\\"') + '"';
	if (typeof data === 'number') return String(data);
	if (typeof data === 'boolean') return data ? 'true' : 'false';

	if (data instanceof Array) {
		let list = [];
		for (let i = 0; i < data.length; i++) {
			let val = stringifyFallback(data[i]);
			if (val === undefined) {
				val = 'null';
			}
			list.push(val);
		}

		return '[' + list.join(',') + ']';
	}

	if (typeof data === 'object') {
		let list = [];
		for (let i in data) {
			let key = stringifyFallback(i);
			let val = stringifyFallback(data[i]);

			if (val === undefined) continue;

			list.push(key + ':' + val);
		}
		return '{' + list.join(',') + '}';
	}

	return '{}';
}

export default {
	encode(data, pretty = false) {
		if (!window.JSON || !window.JSON.stringify) {
			return stringifyFallback(data);
		}

		if (pretty) return JSON.stringify(data, '', 4);
		return JSON.stringify(data);
	},
	decode(json, fallback) {
		try {
			return JSON.parse(json);
		} catch (err) {
			return fallback;
		}
	},
	beautify(json) {
		return this.encode(this.decode(json), true);
	}
}