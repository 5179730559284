export default {
	getRandomString(len = 10) {
		let abc = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		let str = '';
		for (let i = 0; i < len; i++) {
			let idx = Math.floor(Math.random() * abc.length);
			str += abc.charAt(idx);
		}
		return str;
	},
	numFormat(number, sep = ',', decimalsCnt = null) {
		let parts = String(number).split('.', 2);
		let text = parts[0].replace(/(\d)(?=(\d{3})+$)/g, '$1' + sep);
		if (parts[1]) {
			let decimals = parts[1];
			
			if (decimalsCnt !== null) {
				decimals = decimals.slice(0, decimalsCnt);
			}
			text += '.' + decimals;
		}
		return text;
	},
	sleep(timeout) {
		return new Promise(resolve => {
			setTimeout(resolve, timeout);
		});
	},
	filterUnique(value, index, self) {
		return self.indexOf(value) === index;
	},
	isObject(item) {
		return (item && typeof item === 'object' && !Array.isArray(item));
	},
	isObjectEmpty(obj) {
		if (!obj) return true;
		for (let i in obj) return false;
		return true;
	},
	escapeRegex(str, delimiter) {
		let regex = new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\' + (delimiter || '') + '-]', 'g');
		return (str + '').replace(regex, '\\$&');
	},
	deepGet(obj, path, defaultValue = undefined) {
		const travel = regexp =>
			String.prototype.split
				.call(path, regexp)
				.filter(Boolean)
				.reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
		const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
		return result === undefined || result === obj ? defaultValue : result;
	},
	deepMerge(target, ...sources) {
		if (!sources.length) return target;
		const source = sources.shift();
		
		if (this.isObject(target) && this.isObject(source)) {
			for (const key in source) {
				if (this.isObject(source[key])) {
					if (!target[key]) Object.assign(target, { [key]: {} });
					this.deepMerge(target[key], source[key]);
				} else {
					Object.assign(target, { [key]: source[key] });
				}
			}
		}
		
		return this.deepMerge(target, ...sources);
	},
	copy(text) {
		if (navigator.clipboard && navigator.clipboard.writeText) {
			return navigator.clipboard.writeText(text)
		}
		let tf = document.createElement('textarea');
		tf.style.position = 'fixed';
		tf.style.top = '-1000px';
		tf.style.opacity = 0;
		tf.value = text;
		document.body.appendChild(tf);
		
		tf.focus();
		tf.select();
		document.execCommand('copy');
		
		return Promise.resolve();
	},
	capitalize(val) {
		if (!val) return '';
		val = val.toString();
		return val.charAt(0).toUpperCase() + val.slice(1);
	},
	lowerCaseFirst(val) {
		if (!val) return '';
		val = val.toString();
		return val.charAt(0).toLowerCase() + val.slice(1);
	}
}