import json from './json';

export default {
	queryToString(obj) {
		let pairs = [];
		for (let key in obj) {
			if (!obj.hasOwnProperty(key)) continue;
			let val = obj[key];
			if (val === undefined) continue;

			if (val && typeof val === 'object' || val instanceof Array) {
				val = json.encode(val);
			}
			let pair = this.encode(key) + '=' + this.encode(val);
			pairs.push(pair);
		}
		return pairs.join('&');
	},
	encode(raw) {
		return encodeURIComponent(raw);
	},
	decode(raw) {
		return decodeURIComponent(raw);
	},
	getDomainFromUrl(url, keepWww = false) {
		let domain = String(url).trim().toLowerCase().replace(/^https?:\/\//i, '').replace(/\/.*/, '');
		if (!keepWww) {
			domain = domain.replace(/^www\./i, '');
		}
		return domain;
	},
	getRelativeUrl(url) {
		if (!url) return '';
		return url.replace(/^https?:\/\/[^\/]+/i, '');
	},
}