function log() {

}

export default {
	setLogger(fn) {
		log = fn;
	},
	append(parent, child) {
		try {
			parent.appendChild(child);
		} catch (err) {
			log(err);
		}
	},
	before(node, nextNode) {
		try {
			nextNode.parentNode.insertBefore(node, nextNode);
		} catch (err) {
			log(err);
		}
	},
	remove(parent, child) {
		try {
			parent.removeChild(child);
		} catch (err) {
			log(err);
		}
	},
	attr(node, key, val) {
		try {
			node.setAttribute(key, val);
		} catch (err) {
			log(err);
		}
	},
	on(elem, event, callback) {
		if (elem.addEventListener) {
			elem.addEventListener(event, callback, false);
		} else if (elem.attachEvent) {
			elem.attachEvent('on' + event, callback)
		}
	}
}